<template>
  <node-view-wrapper class="vue-component">
    <EditForm v-model="realEditItem" :model="extension.options.HTMLAttributes.model" @validation="validate" @updated_filled="f_formIsFilled"/>

    <!-- save and finish campaign button -->
<!--    {{realEditItem}}-->
<!--    {{extension.options.HTMLAttributes.model}}-->
    <SaveButton :create="createStuff" :disabled="!formIsValid || formIsFilled"
                :is-loading="loading"
                :text="submitText"
                :update="updateStuff" @success="()=>saved(true)"></SaveButton>

    <!-- save campaign button -->
    <SaveButton v-if="saveButton" :create="createStuff"
                :disabled="!formIsValid"
                :is-loading="loading"
                :text="saveText"
                :update="updateStuffNoFinish" @success="()=>saved(false)"></SaveButton>

    <template v-if="editor.isEditable">
      <v-container>
        <v-row>
          <v-col>
            <v-btn color="accent"
                   @click="editForm">Formular bearbeiten
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="formDialog">
        <v-card>
          <v-card-title>
            Formular bearbeiten
          </v-card-title>
          <v-card-text>
            <EditForm ref="modelForm" v-model="editItem" :model="editModel" />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="updateFormModel">Übernehmen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

  </node-view-wrapper>
</template>

<script>
import {nodeViewProps, NodeViewWrapper} from '@tiptap/vue-2'
import gqlCreator from "@/helpers/gqlCreator";
import helpers from "@/helpers/functions";


export default {
  name: "tipTapVueWrapper",
  components: {
    EditForm: () => import('@/components/Forms/EditForm'),
    SaveButton: () => import('@/components/functions/SaveButton'),
    NodeViewWrapper
  },
  props: nodeViewProps,
  data() {
    return {
      existingData: [],
      loading: false,
      saveProto: {
        typeKey: 'CampaignsInput',
        mutationKey: 'campaignsInput',
        list: 'campaignsInput'
      },
      realEditItem: {},
      formDialog: false,
      formModel: {
        "eingabe": "Feldname"
      },
      editItem: {
        editObject: null
      },
      editModel: {
        editObject: {
          type: 'form',
          colspan: 12
        }
      },
      error: null,
      formIsValid: false,
      formIsFilled: false
    }
  },
  computed: {
    submitText() {
      return this.campaign.settings !== undefined ? this.campaign.settings.modules.Veranstaltung.completed : 'abschicken'
    },
    saveText() {
      return this.campaign.settings !== undefined ? this.campaign.settings.modules.Veranstaltung.savetext : 'speichern'
    },
    saveButton() {
      return this.campaign.settings !== undefined && this.campaign.settings.modules.Veranstaltung.saveBtn
    },
    campaign() {
      return this.$props.extension.options.HTMLAttributes.campaign || {};
    },
    record() {
      return this.$props.extension.options.HTMLAttributes.record || {};
    },
    createStuff() {
      let jobs = this.campaign && this.record && this.existingData.length === 0 ? [{
        ...this.saveProto,
        value: {
          name: this.campaign.name,
          context: this.campaign.dataSource,
          campaign: this.campaign.id,
          [this.campaign.dataSource]: this.record.id,
          value: this.realEditItem
        }
      }] : [];
      //create confirmation email
      if (this.campaign.settings && helpers.findValInObj(this.campaign.settings.modules, 'confirmationEmail')) {
        const moduleSettings = this.extension.options.HTMLAttributes.campaign.settings.modules.Veranstaltung; //should be flexibile soon
        //add email job for target record context
        if (moduleSettings.scope !== 'campaignsInputsAddable') {
          jobs.push({
            typeKey: 'Email',
            mutationKey: 'email',
            list: 'email',
            value: {
              sentCheck: this.campaign.id + moduleSettings.recipient + this.record.id + 'Veranstaltung',
              date: new Date(),
              data: {
                formData: this.realEditItem,
                recipient: moduleSettings.recipient,
                content: moduleSettings.emailHTML,
                subject: moduleSettings.subject,
                campaign: this.campaign.id,
                [this.campaign.dataSource]: this.record.id,
                moduleName: 'Veranstaltung'
              }
            }
          });

          if (moduleSettings.sendConfirmationCopy) {
            jobs.push({
              typeKey: 'Email',
              mutationKey: 'email',
              list: 'email',
              value: {
                sentCheck: this.campaign.id + moduleSettings.recipientCC + this.record.id + 'VeranstaltungCC',
                date: new Date(),
                data: {
                  formData: this.realEditItem,
                  recipient: moduleSettings.recipientCC,
                  content: moduleSettings.emailHTML,
                  subject: 'KOPIE ' + moduleSettings.subject,
                  campaign: this.campaign.id,
                  [this.campaign.dataSource]: this.record.id,
                  moduleName: 'Veranstaltung'
                }
              }
            })
          }

        } else {
          //send confirmation to campaign inputs addable
          const addedData = this.realEditItem[moduleSettings.scopeKey];
          if (Array.isArray(addedData)) {
            addedData.forEach((addedRecord, i) => {
              jobs.push({
                typeKey: 'Email',
                mutationKey: 'email',
                list: 'email',
                value: {
                  sentCheck: this.campaign.id + moduleSettings.recipient + this.record.id + i + 'Veranstaltung',
                  date: new Date(),
                  data: {
                    formData: addedRecord,
                    recipient: moduleSettings.recipient,
                    content: moduleSettings.emailHTML,
                    subject: moduleSettings.subject,
                    campaign: this.campaign.id,
                    [this.campaign.dataSource]: this.record.id,
                    moduleName: 'Veranstaltung'
                  }
                }
              })
              //cc email
              if (moduleSettings.sendConfirmationCopy) {
                jobs.push({
                  typeKey: 'Email',
                  mutationKey: 'email',
                  list: 'email',
                  value: {
                    sentCheck: this.campaign.id + moduleSettings.recipientCC + this.record.id + i + 'VeranstaltungCC',
                    date: new Date(),
                    data: {
                      formData: addedRecord,
                      recipient: moduleSettings.recipientCC,
                      content: moduleSettings.emailHTML,
                      subject: 'KOPIE ' + moduleSettings.subject,
                      campaign: this.campaign.id,
                      [this.campaign.dataSource]: this.record.id,
                      moduleName: 'Veranstaltung'
                    }
                  }
                })
              }
            });
          }
        }

      }
      return jobs;
    },
    updateStuffNoFinish() {
      return this.generateUpdateJobs(false);
    },
    updateStuff() {
      return this.generateUpdateJobs(true);
    }
  },
  watch: {
    /*editItem(n,o){
      if(JSON.stringify(n) !== JSON.stringify(o)){
        if(JSON.stringify(n.formular) !== JSON.stringify(o.formular)){
          this.editItem.editObject = JSON.parse(n.formular);
        }
        else{
          this.editItem.formular = JSON.stringify(n.editObject);
        }
      }
    },*/
    existingData() {
      this.updateEditItem();
    },
    formModel() {
      this.editItem.editObject = {...this.$props.extension.options.HTMLAttributes.model};
    }
  },
  methods: {
    generateUpdateJobs(withFinish) {
      if (this.extension.options.HTMLAttributes.campaignUptadeObject === undefined) {
        return [];
      }

      /*
       jobs = [{
         typeKey: typeKey,
         list: listname,
         value: createData
       }];
      *
      * */
      let jobs = this.campaign && this.record && this.existingData.length !== 0 ? [{
        ...this.saveProto,
        value: {
          name: this.campaign.name,
          context: this.campaign.dataSource,
          campaign: this.campaign.id,
          [this.campaign.dataSource]: this.record.id,
          value: this.realEditItem
        }
      }] : [];

      if (withFinish) {
        //set campaign to done
        jobs.push({
          typeKey: this.campaign.dataSource === 'user' ? 'User' : 'Golfvereine',
          mutationKey: this.campaign.dataSource,
          list: this.campaign.dataSource,
          id: this.record.id,
          value: {
            ...this.extension.options.HTMLAttributes.campaignUptadeObject.value
          }
        });
      }

      return jobs
    },
    saved(finish) {
      this.loading = false;
      this.$props.extension.options.HTMLAttributes.reactionHandler(finish);
      this.$apollo.queries.existingData.refetch();
    },
    updateEditItem() {
      if (this.existingData && this.existingData.length > 0) {
        Object.entries(this.existingData[0].value).map(entry => {
          this.$set(this.realEditItem, entry[0], entry[1]);
        });
        this.$set(this.saveProto, 'id', this.existingData[0].id);
      }
    },
    validate(state) {
      this.formIsValid = state;
    },
    f_formIsFilled(state) {
      if(!this.$props.extension.options.HTMLAttributes.model.length > 0 || this.$props.extension.options.HTMLAttributes.model[0].type !== 'addable'){
        this.formIsFilled = false;
      }else{
        this.formIsFilled = state;
      }
    },
    editForm() {
      //console.log("editForm method from TipTapVueWrapper");
      //this.editItem.formular = JSON.stringify(this.$props.extension.options.HTMLAttributes.model);
      this.editItem.editObject = {...this.$props.extension.options.HTMLAttributes.model};
      this.formDialog = true;
    },
    updateFormModel() {
      //console.log("updateFormModel from TipTapVueWrapper");
      try {
        this.formModel = this.$refs.modelForm.$refs.formEditor_editObject[0].modelArray;
        this.updateAttr();
        this.formDialog = false;
      } catch (er) {
        this.error = er.message
      }
    },
    updateAttr() {
      this.$props.editor.storage.tipTapVueWrapper.model = this.formModel;
      this.$props.extension.options.HTMLAttributes.model = this.formModel;
    }
  },
  apollo: {
    existingData: {
      skip() {
        return this.$props.extension.options.HTMLAttributes.campaign === undefined;
      },
      result() {
        this.loading = false;
      },
      query() {
        return gqlCreator.query({
          id: false,
          name: 'name',
          value: 'eingaben'
        }, 'campaignsInputs');
      },
      variables() {
        const record = this.record;
        return {
          where: {
            "campaign": this.campaign.id,
            [this.campaign.dataSource]: record.id
          }
        }
      },
      update(data) {
        return data.campaignsInputs;
      },
    }
  }
}
</script>