import {Node, mergeAttributes} from '@tiptap/core'

import {TextSelection} from "prosemirror-state";
import TipTapVueWrapper from "./TipTapVueWrapper"
import {VueNodeViewRenderer} from '@tiptap/vue-2'

export default Node.create({
    name: 'tipTapVueWrapper',
    addOptions() {
        return {
            HTMLAttributes: {},
        }
    },
    group: 'block',

    addStorage() {
        return {
            model: null,
        }
    },
    onUpdate() {
        // console.log("onUpdate");
        this.storage.model = this.options.HTMLAttributes;
    },
    parseHTML() {
        return [
            {
                tag: 'tip-tap-vue-wrapper',
            },
        ]
    },
    renderHTML({HTMLAttributes}) {
        return ['tip-tap-vue-wrapper', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
    },
    addAttributes(HTMLAttributes) {
        // console.log("addAttributes");
        //this.storage.model = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes);
        return mergeAttributes(this.options.HTMLAttributes, HTMLAttributes);
    },


    addNodeView() {
        return VueNodeViewRenderer(TipTapVueWrapper)
    },
    addCommands() {
        return {
            setForm: () => ({chain}) => {
                return chain()
                    .insertContent({type: this.name})
                    // set cursor after horizontal rule
                    .command(({tr, dispatch}) => {
                        if (dispatch) {
                            const {$to} = tr.selection
                            const posAfter = $to.end()

                            if ($to.nodeAfter) {
                                tr.setSelection(TextSelection.create(tr.doc, $to.pos))
                            } else {
                                // add node after horizontal rule if it’s the end of the document
                                const node = $to.parent.type.contentMatch.defaultType?.create()

                                if (node) {
                                    tr.insert(posAfter, node)
                                    tr.setSelection(TextSelection.create(tr.doc, posAfter))
                                }
                            }

                            tr.scrollIntoView()
                        }

                        return true
                    })
                    .run()
            },
        }
    },
})